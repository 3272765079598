import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { State, process, GroupResult } from "@progress/kendo-data-query";
import * as $ from "jquery";
import { environment } from "@env/environment";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { startWith, map } from 'rxjs/operators';
import { GlobalService } from "@app/shared/services/global/global.service";
import { CompanyService } from "@app/modules/company/services/company.service";
import { LeadsService } from "@app/modules/leads/services/leads.service";


export interface StateGroup {
  letter: string;
  names: string[];
}

export const _filter = (opt, value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter(item => item.name.toLowerCase().includes(filterValue));
};
@Component({
  selector: "crm-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;

  private groupedData: GroupResult[];
  public companyList = [];
  private cList = [];
  private lList = [];
  public leadsList = [];
  stateGroups;
  stateForm = this.fb.group({
    query: '',
  });
  public state: State = {
    skip: 0,
    take: 10,
    //initial filter descriptor
    filter: {
      logic: "and",
      filters: [],
    },
  };;
  userInfo: any;
  imageUrl: any;
  userName: any;
  limit = "5";
  page = "1";
  sortno = "1";
  sortnane = "";
  params = {
    limit: this.limit,
    page: this.page,
    sortno: this.sortno,
    sortnane: this.sortnane,
    client_company_id: 1,
    search: {
      company_name: "",
      company_contact: "",
      company_contact_mob: "",
      company_phone: "",
      company_email: "",
      company_website: "",
      company_country_id: "",
      company_state: "",
      company_city: "",
      company_address1: "",
      company_address2: "",
      company_address3: "",
      company_address4: "",
      company_zip: "",
      company_postal_code: "",
    },
  };
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  leadparams = {
    limit: this.limit,
    page: this.page,
    sortno: this.sortno,
    sortnane: this.sortnane,
    client_company_id: 1,
    search: {
      title: "",
      leadSource: "",
      description: "",
    },
  };


  // public data = [
  //   { name: "Pork", category: "Food", subcategory: "Meat" },
  //   { name: "Pepper", category: "Food", subcategory: "Vegetables" },
  //   { name: "Parsnip", category: "Food", subcategory: "Vegetables" },
  //   { name: "Beef", category: "Food", subcategory: "Meat" },
  //   { name: "Corn flour", category: "Food", subcategory: "Starch" },
  // ];
  public gridView: any;

  showDropDown = true;
  constructor(
    private router: Router,
    private companyService: CompanyService,
    private localStorageService: LocalStorageService,
    private leadsService: LeadsService,
    private fb: FormBuilder,
    private _globalService: GlobalService,
  ) {
    this.initForm();
    // console.log(this.groupedData)
  }
  initForm() {
    console.log("search")
    return this.stateForm = this.fb.group({
      // query: [null]
      query: [""]
    })
  }

  stateGroupOptions;


  private _filterGroup(value: string) {
    console.log("filtered:", value)
    if (value) {
      console.log("inside:", value)
      return this.gridView
        .map(group => ({ category: group.category, field: _filter(group.field, value) }))
        .filter(group => group.field.length > 0);
    }

    console.log("returned value:", this.gridView)
    return this.gridView;
  }



  ngOnInit() {
    this.stateGroupOptions = this.stateForm.get('query')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filterGroup(value || '')),
    );
    console.log(" recived search value:", this.stateGroupOptions)

    this.getUserInfo();
    this.getCompany(this.params);
    this.getLeads(this.leadparams);
    // this.companyService.getSelectedCompanyById().subscribe((response) => {
    //   console.log(response);
    // })
  }

  navigateToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  getUserInfo() {
    this.userName = this.localStorageService.getLocalStorageItem("username");
  }

  logOut(): void { }

  goToProfile() {
    console.log("i pr")
    this.router.navigate(["/profile/"]);

  }

  selectValue(value) {
    this.stateForm.patchValue({ "search": value });
    this.showDropDown = false;
  }
  closeDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  openDropDown() {
    this.showDropDown = false;
  }

  getSearchValue() {
    return this.stateForm.value.search;
  }
  // public filterSettings: DropDownFilterSettings = {
  //   caseSensitive: false,
  //   operator: "startsWith",
  // };
  getCompany(body): void {
    const payload = {
      page: 1,
      limit: 200,
    }
    this.companyService.getCompanyList(payload).subscribe(
      (response) => {
        console.log("company response:", response);
        this.companyList = response.data;
        console.log(this.companyList);
        for (let a of this.companyList) {
          //this.gridView.push(a);
          this.cList = [...this.cList, { name: a.company_name, id: a.company_id }];
        }
        console.log(this.cList)
        this.gridView = [...this.gridView, { category: "Company", field: this.cList }];
        //  this.gridView=[...this.gridView,this.companyList];
        console.log(this.gridView);
        //this.gridView = process(this.companyList, this.state);*/
      },
      (error) => {
        this.gridView = [];

      },
      () => {

      }
    );
  }


  navigateToCompanyDetail(company: any): void {
    console.log("comapny", company);
    // this.companyService.setSelectedCompany(company);
    this.router.navigate(["/company/view", company])

  }
  navigateToLeads() {
    this.router.navigate(["/leads"])
  }
  getLeads(body): void {

    this.leadsService.getSearchLeadsList(body).subscribe(
      (response) => {
        if (response.status) {
          this.leadsList = response.data;
          for (let a of this.leadsList) {
            //this.gridView.push(a);
            this.lList = [...this.lList, { name: a.title, id: a.lead_id }];
          }
          this.gridView = [...this.gridView, { category: "Leads", field: this.lList }]
          console.log("leads");
          //  console.log(this.leadsList);
          //  this.gridView = [...this.gridView,this.leadsList];
          console.log("gridView");
          console.log(this.gridView);

        } else {
          this.gridView = [];
        }
      },
      (error) => {

      },
      () => {

      }
    );
  }





  handleFilter(value) {
    console.log(value);
    this.gridView = this.gridView.filter(
      (s) => (s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) || (s.title.toLowerCase().indexOf(value.toLowerCase()) !== -1)
    );
  }

  myListParem: any;
  myListParemLen: 0;
  private searchTimeout: any;
  viewVisibility: boolean = true;
  newData: Array<any> = []
  arrayList: any;
  globalSearchbar(event: any) {

    // Clear the previous timeout if it exists
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.newData.splice(0, this.newData.length)
    }

    // Set a new timeout
    this.searchTimeout = setTimeout(() => {
      let q1 = event.target.value;
      // Don't proceed if the search query is empty
      if (!q1 || q1.trim() === '') {
        return;
      }
      const searchBody = {
        query: q1
      };
      this._globalService.globalSearchService(searchBody).subscribe(
        (res) => {
          console.log("glbal res:", res);
          if (res.data.companies.length !== 0) {
            this.myListParem = res.data.companies;
            this.newData.push(res.data.companies)
          }
          if (res.data.leads.length !== 0) {
            this.myListParem = res.data.leads;
            this.newData.push(res.data.leads)
          }
          this.myListParemLen = this.myListParem.length;
        }
      );
    }, 500); // 500ms delay (0.5 seconds) - you can change to 5000 for 5 seconds
  }

  searchQuery: any = "";
  selectRecommendation(selectRecommendationData: any) {
    this.viewVisibility = false;
    console.log("glbal selectRecommendationData:", selectRecommendationData)
    this.searchQuery = selectRecommendationData.company_name;
    this.viewVisibility = true;
  }

  onEnter() {
    this.viewVisibility = false;
    this.searchQuery = "";
    this.arrayList = this.newData
    console.log("total array list :", this.newData)
    // this.router.navigate(['/search/list'], {
    //   queryParams: { data: JSON.stringify(this.arrayList) }
    // });


    this.router.navigate(['/search/list', 1], {
      state: { data: JSON.stringify(this.arrayList) }
    });
    this.viewVisibility = true;

  }



}
