import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminPanelComponent } from "./core/layout/admin-panel/admin-panel.component";
import { AuthGuard } from "./core/guards/auth/auth.guard";

const routes: Routes = [
  {
    path: "login",
    loadChildren: "@modules/auth/login/login.module#LoginModule",
  },
  {
    path: "",
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: "@modules/dashboard/dashboard.module#DashboardModule",
        data: {
          breadcrumb: "Dashboard",
        },
      },
      {
        path: "company",
        loadChildren: "@modules/company/company.module#CompanyModule",
        data: {
          breadcrumb: "Company",
        },
      },
      {
        path: "search",
        loadChildren: "@modules/searchList/searchModule.module#SearchModuleModule",
        data: {
          breadcrumb: "Search",
        },
      },
      {
        path: "leads",
        loadChildren: "@modules/leads/leads.module#LeadsModule",
        data: {
          breadcrumb: "Leads",
        },
      },
      {
        path: "lead-contacts",
        loadChildren:
          "@modules/lead-contacts/lead-contacts.module#LeadContactsModule",
        data: {
          breadcrumb: "Lead Contacts",
        },
      },
      {
        path: "bid-information",
        loadChildren:
          "@modules/bid-information/bid-information.module#BidInformationModule",
        data: {
          breadcrumb: "Bid Information",
        },
      },
      {
        path: "groups",
        loadChildren: "@modules/groups/groups.module#GroupsModule",
        data: {
          breadcrumb: "Groups",
        },
      },
      {
        path: "merge-fields",
        loadChildren:
          "@modules/merge-fields/merge-fields.module#MergeFieldsModule",
        data: {
          breadcrumb: "Merge Fields",
        },
      },
      {
        path: "email-template",
        loadChildren:
          "@modules/email-template/email-template.module#EmailTemplateModule",
        data: {
          breadcrumb: "Email Template",
        },
      },
      {
        path: "create-email",
        loadChildren:
          "@modules/create-email/create-email.module#CreateEmailModule",
        data: {
          breadcrumb: "Create Email",
        },
      },
      {
        path: "send-queue-email",
        loadChildren:
          "@modules/send-queue-email/send-queue-email.module#SendQueueEmailModule",
        data: {
          breadcrumb: "Send Queue Email",
        },
      },
      {
        path: "marketing",
        loadChildren: "@modules/marketing/marketing.module#MarketingModule",
        data: {
          breadcrumb: "Marketing",
        },
      },
      {
        path: "stages",
        loadChildren: "@modules/stages/stages.module#StagesModule",
        data: {
          breadcrumb: "Stages",
        },
      },
      {
        path: "product-category",
        loadChildren:
          "@modules/product-category/product-category.module#ProductCategoryModule",
        data: {
          breadcrumb: "Product Category",
        },
      },
      {
        path: "products",
        loadChildren: "@modules/products/products.module#ProductsModule",
        data: {
          breadcrumb: "Products",
        },
      },
      {
        path: "products-stages",
        loadChildren:
          "@modules/products-stages/products-stages.module#ProductsStagesModule",
        data: {
          breadcrumb: "Products Stages",
        },
      },
      {
        path: "change-password",
        loadChildren:
          "@modules/change-password/change-password.module#ChangePasswordModule",
        data: {
          breadcrumb: "Change Password",
        },
      },
      {
        path: "setting",
        loadChildren:
          "@modules/setting/setting.module#SettingModule",
        data: {
          breadcrumb: "Setting",
        },
      },
      {
        path: "user",
        loadChildren:
          "@modules/user/user.module#UserModule",
        data: {
          breadcrumb: "User",
        },
      },
      {
        path: "profile",
        loadChildren:
          "@modules/profile/userProfile.module#UserProfileModule",
        data: {
          breadcrumb: "User Profile",
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // to emit router events when we try to navigate to the same URL
      onSameUrlNavigation: "reload",
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule { }
