import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminPanelComponent } from "./admin-panel/admin-panel.component";
import { NavBarComponent } from "./admin-panel/nav-bar/nav-bar.component";
import { SideNavComponent } from "./admin-panel/side-nav/side-nav.component";
import { MenuItemComponent } from "./admin-panel/side-nav/menu-item/menu-item.component";
import { BreadcrumbsModule } from "ng6-breadcrumbs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./admin-panel/footer/footer.component";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { CookieService } from "ngx-cookie-service";
import {  ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    AdminPanelComponent,
    NavBarComponent,
    SideNavComponent,
    MenuItemComponent,
    FooterComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,  ReactiveFormsModule ,MatAutocompleteModule,
    MatInputModule,MatButtonModule,
    RouterModule,
    DropDownsModule,

    InputsModule,
    ButtonsModule,
    BrowserAnimationsModule,
    Ng2SearchPipeModule,
    BreadcrumbsModule,
    FormsModule,
    RouterModule,
    
  ],
  entryComponents: [FooterComponent, ConfirmationDialogComponent],
  exports: [FooterComponent, ConfirmationDialogComponent],
  providers: [CookieService],
})
export class LayoutModule {}
