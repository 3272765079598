import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  LocationStrategy,
  HashLocationStrategy,
  DatePipe,
} from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./core/inteceptors/token.interceptor";
import { ToastrModule } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { SearchFilterPipe } from "./shared/pipes/filter/search-filter.pipe";
import { UserProfileComponent } from './modules/profile/components/user-profile/user-profile.component';
@NgModule({
  declarations: [AppComponent,SearchFilterPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
      progressBar: false,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true,
    }),
  ],
  providers: [
    [DatePipe],
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
