import { Component, OnInit } from "@angular/core";

import { LayoutService } from "../../services/layout.service";
import { IconConst } from "../../../../shared/constants/icon.constant";
import { environment } from "@env/environment.prod";
import { LoginService } from "@app/modules/auth/login/services/login.service";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";

@Component({
  selector: "crm-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;
  imageUrl = environment.defaultImageUrl;
  iconConst = IconConst;

  navItems = [
    {
      id: 1,
      displayName: "Dashboard",
      iconName: this.iconConst.DASHBOARD,
      route: "/dashboard",
    },
    {
      id: 2,
      displayName: "Company",
      iconName: "fa fa-building",
      route: "company",
    },
    // {
    //   id: 3,
    //   displayName: "Leads",
    //   iconName: "fa fa-bullhorn",
    //   route: "leads",
    // },
    {
      id: 4,
      displayName: "Lead Contacts",
      iconName: this.iconConst.CONTACT,
      route: "lead-contacts",
    },
    /*   {
         id: 5,
         displayName: "Bid Information",
         iconName: this.iconConst.BID_INFORMATION,
         route: "bid-information",
       },
       {
         id: 6,
         displayName: "Groups",
         iconName: this.iconConst.GROUPS,
         route: "groups",
       },
       {
         id: 7,
         displayName: "Merge Fields",
         iconName: this.iconConst.MERGE_FIELDS,
         route: "merge-fields",
       },
       {
         id: 8,
         displayName: "Email Template",
         iconName: this.iconConst.EMAIL_TEMPLATE,
         route: "email-template",
       },
       {
         id: 9,
         displayName: "Create Email",
         iconName: this.iconConst.EMAIL_TEMPLATE,
         route: "create-email",
       },
       {
         id: 10,
         displayName: "Send Queue Email",
         iconName: this.iconConst.SEND_EMAIL,
         route: "send-queue-email",
       },
       {
         id: 11,
         displayName: "Marketing",
         iconName: this.iconConst.MARKETING,
         route: "marketing",
       },
       {
         id: 12,
         displayName: "Stages",
         iconName: this.iconConst.MERGE_FIELDS,
         route: "stages",
       },
       {
         id: 13,
         displayName: "Product Category",
         iconName: this.iconConst.PRODUCT,
         route: "product-category",
       },
       {
         id: 14,
         displayName: "Products",
         iconName: this.iconConst.PRODUCT,
         route: "products",
       },
       {
         id: 15,
         displayName: "Products Stages",
         iconName: this.iconConst.MERGE_FIELDS,
         route: "products-stages",
       },*/
    {
      id: 16,
      displayName: "Change Password",
      iconName: this.iconConst.PASSWORD,
      route: "change-password",
    },
    {
      id: 17,
      displayName: "Setting",
      iconName: this.iconConst.SETTING,
      route: "setting",
    },
    {
      id: 18,
      displayName: "User",
      iconName: this.iconConst.USER,
      route: "user",
    },
  ];

  constructor(
    private layoutService: LayoutService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.getUserInfo();
  }

  userName: string;
  userInfo: any;

  getUserInfo() {
    // this.imageUrl = this.localStorageService.getLocalStorageItem("image_path");
    // this.userInfo = this.localStorageService.getLocalStorageItem("user_detail");
    this.userName = this.layoutService.getUserFullName();
  }

  toogleSideNav() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }
}
