import { Injectable } from "@angular/core";
import { Company } from "../model/company.model";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class CompanyService {


  api_url = environment.baseIP + environment.apiPrefix;
  access_token: any;
  constructor(
    private httpClient: HttpClient,
    private _cookieService: CookieService,
  ) {
    this.access_token = this._cookieService.get("access_token")
    //this.access_token = this._cookieService.getAll();
  }

  // getCompanyLists(body): Observable<any> {
  //   return this.httpClient.post(`${this.api_url}company/search`, body);
  // }
  selectedCompany;
  setSelectedCompany(company: Company) {
    this.selectedCompany = company;
  }

  getSelectedCompany() {
    return this.selectedCompany;
  }


  getCompanyList(body: any): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.api_url}company/list`, body, options);
  }
  deleteCompanyLists(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.api_url}company/remove`, body, options);
  }

  addCompanyLists(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.api_url}company/create`, body, options);
  }
  updateCompanyLists(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.api_url}company/edit`, body,options);
  }

  companyListSearch(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.api_url}company/list`, body, options);
  }



}
