import { Injectable } from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@env/environment";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  apiUrl = environment.baseIP + environment.apiPrefix;
  access_token: any;

  constructor(
    private httpClient: HttpClient,
    private _cookieService: CookieService,
  ) {
    this.access_token = this._cookieService.get("access_token")
  }

  public markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }

  // Get the list of countries
  getCountryList(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.get(`${this.apiUrl}dropdown/list`, options);
  }

  globalSearchService(body): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.apiUrl}site/global-search`, body, options);
  }


}
