import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: "root",
})
export class LoginService {
  apiUrl = environment.baseIP + environment.apiPrefix;
  access_token: any;
  constructor(
    private httpClient: HttpClientService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {
    this.access_token = this.cookieService.get("access_token")
  }



  login(body) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    console.log("access:", options)
    return this.httpClient.post(`${this.apiUrl}user/login`, body);

  }

  getTokenFromCookie() {
    return this.cookieService.get("access_token");
  }

  setUserInfoOnStorage(userInfo) {
    this.localStorageService.setLocalStorageItem("user_info", userInfo);
  }

  getUserInfoFromStorage() {
    return this.localStorageService.getLocalStorageItem("user_info");
  }
}
