import { Component, OnInit, Input } from "@angular/core";
import { LayoutService } from "../../../services/layout.service";
declare var $: any;

@Component({
  selector: "crm-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent implements OnInit {
  @Input() navItems;
  expanded: boolean;
  term: any;

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      if (trees) {
        trees.tree();
      }
    });
  }

  // This function is requred if the menu items have children items
  // setExpandedTrue(event) {
  //   if ((event.target.value! = "")) {
  //     this.expanded = true;
  //   } else {
  //     this.expanded = false;
  //   }
  // }

  setTitle(title) {
    this.layoutService.setContentTitle(title);
  }
}
