import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { HttpClientService } from "@app/core/services/http-client/http-client.service";
import { Leads } from "../models/leads.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  apiUrl = environment.baseIP + environment.apiPrefix;
  access_token: any;
  constructor(
    private httpClient: HttpClient,
    private _cookieService: CookieService,
  ) {
    this.access_token = this._cookieService.get("access_token")
  }

  getSearchLeadsList(body): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}lead/search`, body);
  }

  getLeadsList(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}lead/index`);
  }

  // addLeadsList(body): Observable<any> {
  //   return this.httpClient.post(`${this.apiUrl}lead/add`, body);
  // }

  updateLeadsList(body, id): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}lead/edit`, body);
  }

  deleteLeadsList(body): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}lead/remove`, body);
  }
  getLeadsSources(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}lead/leadsource`)
  }

  selectedLeads;
  setSelectedLeads(leads: Leads): void {
    this.selectedLeads = leads;
  }

  getSelectedLeads() {
    return this.selectedLeads;
  }
  selectedEmail;
  setSelectedEmail(email): void {
    this.selectedEmail = email;
  }

  getSelectedEmail(): void {
    return this.selectedEmail;
  }

  getLeadsLists(body: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.apiUrl}leads/list`, body, options);
  }


  addLeadsList(body): Observable<any> {
    console.log("add body:",body)
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + this.access_token
      }),
    };
    return this.httpClient.post(`${this.apiUrl}leads/add`, body,options);
  }
}
