import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatProgressBarModule } from "@angular/material";
import { LoaderComponent } from "./component/loader.component";
import { LoaderService } from "./service/loader.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoaderInterceptor } from "../inteceptors/loader.interceptor";

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, MatProgressBarModule],
  exports: [LoaderComponent, MatProgressBarModule],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
})
export class LoaderModule {}
